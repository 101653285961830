
.reviews {
  padding-top: 50px;
  padding-bottom: 50px;

  .quote {
    color: rgba(0,0,0,.1);
    text-align: center;
    margin-bottom: 30px;
  }
}



/*-------------------------------*/
/*    Carousel Fade Transition   */
/*-------------------------------*/

#fade-quote-carousel.carousel {
  padding-bottom: 60px;
}
#fade-quote-carousel.carousel .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
#fade-quote-carousel.carousel .carousel-inner .active {
  opacity: 1;
  -webkit-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
#fade-quote-carousel.carousel .carousel-indicators {
  bottom: 10px;
}
#fade-quote-carousel.carousel .carousel-indicators > button {
  background-color: #fccd39;
  border: none;
}
#fade-quote-carousel blockquote {
  text-align: center;
  border: none;
}
#fade-quote-carousel .profile-circle {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 100px;
}
#fade-quote-carousel {

}