//
//  Fonts
$root-font-size: 16;
$base-font-family: 'Roboto', sans-serif;
$base-font-thin: 'Roboto Light', sans-serif;
$base-font-medium: 'Roboto Medium', sans-serif;
$base-font-bold: 'Roboto Bold', sans-serif;
$base-font-bolder: 'Roboto Black', sans-serif;
$base-line-height: 1;
$base-font-weight: 400;

//
//  Breakpoints
$smavnp-screen: 480px;
$medium-screen: 768px;
$large-screen: 992px;

$smavnp-screen-up: 'min-width: #{$smavnp-screen}';
$medium-screen-up: 'min-width: #{$medium-screen}';
$large-screen-up: 'min-width: #{$large-screen}';
$breakpoint-xs:0;
$breakpoint-sm:576px;
$breakpoint-md:768px;
$breakpoint-lg:1024px;
$breakpoint-xl:1399px;

// Off Canvas
$off-canvas-shadow-color: rgba(0, 0, 0, 0.3);
$off-canvas-overlay-color: #000000;
$off-canvas-width: 300px;
$off-canvas-padding-size: 20px;
$off-canvas-transition-time: 300ms;
$off-canvas-transition-style: ease-in-out;
$off-canvas-box-shadow: -10px 0 10px -5px $off-canvas-shadow-color inset;
$off-canvas-overlay-opacity: 0.5;


//
//  Colors
$vnp-brand-primary:#ffcf29;
$vnp-brand-secondary:#050d24;
$vnp-brand-white:#fff;
$vnp-brand-off-white:#f5f6fa;
$vnp-brand-title:#3d3d3d;
$vnp-brand-card-title:#a1a4a8;
$vnp-brand-subtext:#b4b4b4;
$vnp-brand-text:#8a8a8a;
$vnp-brand-icon:#e8e9e9;
$vnp-brand-icon-text:#727272;
$vnp-brand-counter: #9d9ea2;
$vnp-brand-forms: #f6f7f9;
$vnp-brand-widget1-primary:#fff7db;
$vnp-brand-widget1-secondary:#fccd39;
$vnp-brand-widget2-primary:#eff8fe;
$vnp-brand-widget2-secondary:#81c0ec;
$vnp-brand-widget3-primary:#d3fde1;
$vnp-brand-widget3-secondary:#97dfaf;

$brand-color: #000000;
$brand-secondary: #F8F8FB;
$blue:#007bff;
$indigo:#6610f2;
$purple:#6f42c1;
$pink:#e83e8c;
$red:#dc3545;
$orange:#fd7e14;
$yevnpow:#ffc107;
$green:#28a745;
$teal:#20c997;
$cyan:#17a2b8;
$white:#fff;
$gray:#6c757d;
$gray-dark:#343a40;
$primary:#5867dd;
$secondary:#e1e1ef;
$success:#1dc9b7;
$info:#5578eb;
$warning:#ffb822;
$danger:#fd397a;
$light:#f8f9fa;
$dark:#343a40;
$navy: #252939;
$dark-navy: #1F2433;
$light-gray:#989eb3;
$main-bg: #F0F1F6;
$off-white: #f9f9fc;
