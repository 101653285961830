
.button {
  border-radius: 10px;
  padding: 10px 30px;
  &:hover{
    animation: pulse;
    animation-duration: 1s;
  }

}
.button-yellow {
  background-color: #fccd39 !important;
}