//
//  Base
//  ---------------------------------------------
html, body, .off-canvas-wrapper  {
  width: 100%;
  height: 100%;
}
html {
  font-size: 14px;
}
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background: #f9f9fc;
  //background: #f9f9fc;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  //background-color: #F0F1F6;
}

/*******************************
             Headers
*******************************/

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem -  0.14285714em ) 0em 1rem;
  font-weight: bold;
  padding: 0em;
}
h1 {
  min-height: 1rem;
  font-size: 2rem;
}
h2 {
  font-size: 1.71428571rem;
}
h3 {
  font-size: 1.28571429rem;
}
h4 {
  font-size: 1.07142857rem;
}
h5 {
  font-size: 1rem;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0em;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0em;
}


/*******************************
             Text
*******************************/

p {
  margin: 0em 0em 1em;
  line-height: 1.4285em;
}
p:first-child {
  margin-top: 0em;
}
p:last-child {
  margin-bottom: 0em;
}

/*-------------------
        Links
--------------------*/

a {
  color: #4183C4;
  text-decoration: none;
}
a:hover {
  color: #1e70bf;
  text-decoration: none;
}
a { cursor: pointer; }

.ll-main {
  background-color: $off-white;
}

.background-none {
  background-color: transparent;
}
.placeholder {
  background: rgb(255,240,120);
  &:before {
    content: "Drop here";
    color: rgb(225,210,90);
  }
}
li {
  padding: 10px 15px;
  &:hover {
    background: darken(#f9f9f9, 5%);
  }
}
.ll-table {
  padding: 0;
}
.no-date-fix {
  margin-top:39px;
}
.abcentered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

  .form-signin {
    width: 100%;
    max-width: 900px;
    padding: 15px;
    margin: 0 auto;
  }

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  width:100%;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}