
  .nav-item {
    padding: 10px;
    &:hover{
      background: none;
    }
  }
  .nav-link {
    border-bottom: transparent 2px solid;
    &:hover{
      border-bottom: #fccd39 2px solid;
    }
  }

